import React, {useContext} from 'react';
import { Layout, Space, Typography, Button } from 'antd';
import { HomePageContext } from '../contexts/AppContext';
import './callToAction.css';

const CallToAction = () => {
    const { Content } = Layout;
    const { translate } = useContext(HomePageContext);

    return (
        <>
            <Space id='action-main-container'>
                <Content role="main" id='action-container'>
                    <div className='action-content-texts'>
                        <Typography.Title role="heading" level={2} className="section-caption-center">{translate('call_action_title')}</Typography.Title>
                        <Button role="button" aria-label={'learn more'} type='primary' ghost>{translate('get_started')}</Button>
                    </div>
                </Content>
            </Space>
        </>
    )
}

export default CallToAction