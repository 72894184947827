import React, { useContext } from 'react';
import { Button, Layout, Space, Typography } from 'antd';
import IntegrationBox from './IntegrationBox';
import { HomePageContext } from '../contexts/AppContext';
import './integration.css';

const Integration = () => {
    const { Content } = Layout;
    const { translate } = useContext(HomePageContext);

    return (
        <>
            <Space>
                <Content id='integration-container'>
                    <div className='customer-content-texts'>
                        <Typography.Title role="heading" level={5} className="section-title-left">{translate('our_integration')}</Typography.Title>
                        <Typography.Title role="heading" level={3} className="section-caption-left">{translate('our_integration_title')}</Typography.Title>
                        <Typography.Paragraph role="textbox" className='section-paragraph'>{translate('our_integration_text')}</Typography.Paragraph>
                        <Button role="button" aria-label={'learn more'} type='primary' ghost>{translate('btn_integrate_app')}</Button>
                    </div>
                    <div className='customer-image-animation'>
                        <IntegrationBox />
                    </div>
                </Content>
            </Space>
        </>
    )
}

export default Integration