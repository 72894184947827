import React from 'react';
import Navbar from './components/navbar/Navbar';
import Header from './components/header/Header';
import Services from './components/services/Services';
import Customer from './components/customer/Customer';
import Tutorial from './components/tutorial/Tutorial';
import Integration from './components/integrations/Integration';
import Testimonials from './components/testimonials/Testimonials';
import CallToAction from './components/callToAction/CallToAction';
import Copyright from './components/copyright/Copyright';
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import Layout from './components/contexts/AppContext';
import { Helmet } from 'react-helmet';
import './App.css';


const client = new ApolloClient({
  uri: process.env.REACT_APP_STRAPI_API_URL,
  cache: new InMemoryCache(),
});


function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <Layout>
            <Helmet>
              <title>Formrelic | HomePage</title>
            </Helmet>
            <Navbar />
            <Header />
            <Services />
            <Customer />
            <Tutorial />
            <Integration />
            <Testimonials />
            <CallToAction />
            <Copyright />
        </Layout>
      </ApolloProvider>
    </>
  );
}

export default App;