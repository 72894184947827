import React, { useContext } from 'react';
import { Layout, Space, Typography } from 'antd';
import TestimonialBox from './TestimonialBox';
import { HomePageContext } from '../contexts/AppContext';
import './testimonial.css';

const Testimonials = () => {
    const { Content } = Layout;
    const { translate } = useContext(HomePageContext);

    return (
        <>
            <Space id='testimonial-container'>
                <Content className='main-testimonial-container' role="main">
                    <Typography.Title role="heading" level={5} className="section-title">{translate('client_testimonial')}</Typography.Title>
                    <Typography.Title role="heading" level={3} className="section-caption">{translate('client_testimonial_title')}</Typography.Title>
                    <div role="region" className='testimonial-box'>
                        <TestimonialBox />
                    </div>
                </Content>
            </Space>
        </>
    )
}

export default Testimonials