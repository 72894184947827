import React, { useEffect, useState, useContext } from 'react';
import { Typography, Avatar } from 'antd';
import user1 from '../../images/user1.jpg';
import user2 from '../../images/user2.jpg';
import user3 from '../../images/user3.jpg';
import { HomePageContext } from '../contexts/AppContext';

const TestimonialBox = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { translate } = useContext(HomePageContext);
    const intervalTime = 5000;
    const Testimonials = [
        {
            url: user1,
            text: `${translate('testimonial_1')}`,
            name: `${translate('user_testimonial_1')}`,
            position: `${translate('position_testimonial_1')}`
        },
        {
            url: user2,
            text: `${translate('testimonial_2')}`,
            name: `${translate('user_testimonial_2')}`,
            position: `${translate('position_testimonial_2')}`
        },
        {
            url: user3,
            text: `${translate('testimonial_3')}`,
            name: `${translate('user_testimonial_3')}`,
            position: `${translate('position_testimonial_3')}`
        }
    ];

    useEffect(() => {
        const slideToNext = () => {
            setCurrentIndex(currentIndex === Testimonials.length - 1 ? 0 : currentIndex + 1);
        }
        const interval = setInterval(slideToNext, intervalTime);

        return () => clearInterval(interval);
    }, [currentIndex, Testimonials.length]);

    return (
        <>
            <div className='carousel-card'>
                <div className='avatar-img'>
                    <Avatar className='avatar' src={Testimonials[currentIndex].url} />
                </div>
                <div className='testimonial-infos'>
                    <Typography.Paragraph className='testimonial' role="textbox">{Testimonials[currentIndex].text}</Typography.Paragraph>
                    <Typography.Text className='name' role="textbox">{Testimonials[currentIndex].name}</Typography.Text>
                    <Typography.Text className='position' role="textbox">{Testimonials[currentIndex].position}</Typography.Text>
                </div>
            </div>

        </>
    )
}

export default TestimonialBox