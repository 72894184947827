import React, {useContext} from 'react';
import { Layout, Space, Typography } from 'antd';
import { HomePageContext } from '../contexts/AppContext';
import './copyright.css';

const Copyright = () => {
    const { Content } = Layout;
    const { translate } = useContext(HomePageContext);

    return (
        <>
            <Space id='copy-main-container'>
                <Content role="main" id='copy-container'>
                        <Typography.Title role="heading" level={5} className="section-text-center">{translate('copyright')}</Typography.Title>
                </Content>
            </Space>
        </>
    )
}

export default Copyright